// src/TaxCalculator.js

import React, { useState } from "react";
import axios from "axios";

// Import React Bootstrap components
import {
  Container,
  Form,
  Button,
  Alert,
  InputGroup,
  FormControl,
  Row,
  Col,
  Card,
  Collapse,
} from "react-bootstrap";

function TaxCalculator() {
  const [income, setIncome] = useState("");
  const [pensionContribution, setPensionContribution] = useState("");
  const [tax, setTax] = useState(null);
  const [nationalInsurance, setNationalInsurance] = useState(null);
  const [error, setError] = useState("");
  const [pensionContributionAmount, setPensionContributionAmount] = useState(
    null
  );
  const [claimBackReliefType, setClaimBackReliefType] = useState("");
  const [claimBackAmount, setClaimBackAmount] = useState("");

  const [showForm, setShowForm] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [comments, setComments] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setTax(null);
    setNationalInsurance(null);
    setPensionContributionAmount(null);

    try {
      const response = await axios.post("/api/calculateTax", {
        income: parseFloat(income),
        pensionContribution: parseFloat(pensionContribution),
        claimBackReliefType: claimBackReliefType || null,
        claimBackAmount: claimBackAmount
          ? parseFloat(claimBackAmount)
          : null,
      });

      setTax(response.data.tax);
      setNationalInsurance(response.data.nationalInsurance);
      setPensionContributionAmount(
        parseFloat(income) * (parseFloat(pensionContribution) / 100)
      );
    } catch (err) {
      setError("Error Calculating Tax. Please check inputs.");
    }
  };

  const handleContactSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post("/api/contactAccountant", {
        name,
        email,
        comments,
        income: parseFloat(income),
        tax,
        nationalInsurance,
      });
      alert("Your information has been submitted. We'll contact you soon.");
    } catch (err) {
      alert("Error submitting information. Please try again");
    }
  };

  return (
    <Container className="my-5">
      <h1 className="text-center mb-4">Income Tax Calculator</h1>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="income">
          <Form.Label>Income:</Form.Label>
          <InputGroup>
            <InputGroup.Text>£</InputGroup.Text>
            <FormControl
              type="number"
              value={income}
              onChange={(e) => setIncome(e.target.value)}
              required
              placeholder="Enter your annual income"
            />
          </InputGroup>
        </Form.Group>

        <Form.Group controlId="pensionContribution">
          <Form.Label>Pension Contribution (%):</Form.Label>
          <FormControl
            type="number"
            value={pensionContribution}
            onChange={(e) => setPensionContribution(e.target.value)}
            required
            placeholder="Enter pension contribution percentage"
          />
        </Form.Group>

        <Form.Group controlId="claimBackReliefType">
          <Form.Label>Claim Back Relief Type (Optional):</Form.Label>
          <Form.Control
            as="select"
            value={claimBackReliefType}
            onChange={(e) => setClaimBackReliefType(e.target.value)}
          >
            <option value="">-- Select Relief Type --</option>
            <option value="uniforms_workclothing_tools">
              Uniforms, work clothing and tools
            </option>
            <option value="vehicles">Vehicles you use for work</option>
            <option value="travel_overnight">
              Travel and overnight expenses
            </option>
            <option value="professional_fees">
              Professional fees and subscriptions
            </option>
            <option value="buying_other_equipment">
              Buying other equipment
            </option>
            <option value="other_expenses">Other expenses</option>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="claimBackAmount">
          <Form.Label>Claim Back Relief Amount (£) (Optional):</Form.Label>
          <InputGroup>
            <InputGroup.Text>£</InputGroup.Text>
            <FormControl
              type="number"
              value={claimBackAmount}
              onChange={(e) => setClaimBackAmount(e.target.value)}
              placeholder="Enter claim back relief amount"
            />
          </InputGroup>
        </Form.Group>

        <Button variant="primary" type="submit" className="mt-3">
          Calculate Tax
        </Button>
      </Form>

      {error && (
        <Alert variant="danger" className="mt-3">
          {error}
        </Alert>
      )}

      {tax !== null && (
        <Card className="mt-4">
          <Card.Header>Calculation Results</Card.Header>
          <Card.Body>
            <Row>
              <Col md={6}>
                <p>
                  <strong>Annual Employee Pension Contribution:</strong> £
                  {pensionContributionAmount.toFixed(2)}
                </p>
                <p>
                  <strong>Calculated Tax:</strong> £{tax.toFixed(2)}
                </p>
                <p>
                  <strong>National Insurance:</strong> £
                  {nationalInsurance.toFixed(2)}
                </p>
                <p>
                  <strong>Take Home Pay:</strong> £
                  {(
                    parseFloat(income) -
                    tax -
                    nationalInsurance -
                    pensionContributionAmount
                  ).toFixed(2)}
                </p>
              </Col>
            </Row>
            <Button
              variant="success"
              className="mt-3"
              onClick={() => setShowForm(!showForm)}
              aria-controls="contact-form"
              aria-expanded={showForm}
            >
              Find an Accountant
            </Button>
          </Card.Body>
        </Card>
      )}

      <Collapse in={showForm}>
        <div id="contact-form">
          <Card className="mt-4">
            <Card.Header>Submit Your Information</Card.Header>
            <Card.Body>
              <Form onSubmit={handleContactSubmit}>
                <Form.Group controlId="contactName">
                  <Form.Label>Name:</Form.Label>
                  <FormControl
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                    placeholder="Enter your name"
                  />
                </Form.Group>

                <Form.Group controlId="contactEmail">
                  <Form.Label>Email:</Form.Label>
                  <FormControl
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    placeholder="Enter your email"
                  />
                </Form.Group>

                <Form.Group controlId="contactComments">
                  <Form.Label>Additional Comments (Optional):</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                    placeholder="Enter any additional comments"
                  />
                </Form.Group>

                <Button variant="primary" type="submit" className="mt-3">
                  Submit
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </Collapse>
    </Container>
  );
}

export default TaxCalculator;
