import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './components/HomePage';
import TaxCalculator from './TaxCalculator';
import NavigationBar from './components/NavigationBar';
import AboutPage from './components/AboutUs';
import TermsOfServicePage from './components/TermsOfServicePage';


function App() {
    return (
      <Router>

        <NavigationBar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/calculator" element={<TaxCalculator />} />
          <Route path="/about" element={<AboutPage />} /> 
          <Route path="/terms-of-service" element={<TermsOfServicePage />} />
        </Routes>
      </Router>
    );
  }
  

export default App;
