// src/components/TermsOfServicePage.js
import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

const TermsOfServicePage = () => (
  <Container className="mt-5">
    <Row>
      <Col>
        <h1>Terms of Service</h1>
        <p className="text-muted">Effective Date: 11/10/2024</p>
        <p>
          Welcome to Taxclip.co.uk (the "Website"). By accessing or using our Website, you agree to comply with and be bound by these Terms of Service ("Terms"). If you do not agree with these Terms, please do not use our Website.
        </p>

        {/* Section 1: Overview */}
        <Card className="mb-3">
          <Card.Body>
            <Card.Title>1. Overview</Card.Title>
            <Card.Text>
              Taxclip.co.uk ("we", "our", or "us") provides services and information related to tax guidance and resources. The Website aims to assist users ("you" or "user") with tax-related information and resources but does not substitute for professional advice or services.
            </Card.Text>
          </Card.Body>
        </Card>

        {/* Section 2: Eligibility */}
        <Card className="mb-3">
          <Card.Body>
            <Card.Title>2. Eligibility</Card.Title>
            <Card.Text>
              To use our Website, you must be at least 18 years old and have the legal capacity to enter into this agreement. By using Taxclip.co.uk, you confirm that you meet these requirements.
            </Card.Text>
          </Card.Body>
        </Card>

        {/* Section 3: Use of the Website */}
        <Card className="mb-3">
          <Card.Body>
            <Card.Title>3. Use of the Website</Card.Title>
            <Card.Text>
              You agree to use Taxclip.co.uk only for lawful purposes and in accordance with these Terms. You shall not:
            </Card.Text>
            <ul>
              <li>Use the Website in any manner that could disable, overburden, damage, or impair the Website or interfere with other users' use of the Website.</li>
              <li>Attempt to gain unauthorized access to any part of the Website, its systems, servers, or networks connected to the Website.</li>
              <li>Upload, post, or transmit any material that is defamatory, offensive, or otherwise objectionable.</li>
            </ul>
          </Card.Body>
        </Card>

        {/* Section 4: Intellectual Property */}
        <Card className="mb-3">
          <Card.Body>
            <Card.Title>4. Intellectual Property</Card.Title>
            <Card.Text>
              All content, trademarks, logos, graphics, and other materials on Taxclip.co.uk are the property of Taxclip.co.uk or its licensors. You may not reproduce, distribute, or create derivative works from any of the content on the Website without our express written permission.
            </Card.Text>
          </Card.Body>
        </Card>

        {/* Section 5: Disclaimer of Professional Advice */}
        <Card className="mb-3">
          <Card.Body>
            <Card.Title>5. Disclaimer of Professional Advice</Card.Title>
            <Card.Text>
              The information provided on Taxclip.co.uk is for general informational purposes only and does not constitute tax advice or professional services. We recommend consulting a qualified professional for specific advice tailored to your situation.
            </Card.Text>
          </Card.Body>
        </Card>

        {/* Section 6: Limitation of Liability */}
        <Card className="mb-3">
          <Card.Body>
            <Card.Title>6. Limitation of Liability</Card.Title>
            <Card.Text>
              To the fullest extent permitted by law, Taxclip.co.uk and its affiliates shall not be liable for any direct, indirect, incidental, special, or consequential damages arising from your use or inability to use the Website or its content, even if we have been advised of the possibility of such damages.
            </Card.Text>
          </Card.Body>
        </Card>

        {/* Section 7: Third-Party Links */}
        <Card className="mb-3">
          <Card.Body>
            <Card.Title>7. Third-Party Links</Card.Title>
            <Card.Text>
              Taxclip.co.uk may contain links to third-party websites or services that are not owned or controlled by us. We are not responsible for the content or practices of any third-party websites. You acknowledge and agree that Taxclip.co.uk is not liable for any damage or loss caused by the use of or reliance on any such content or services.
            </Card.Text>
          </Card.Body>
        </Card>

        {/* Section 8: Privacy Policy */}
        <Card className="mb-3">
          <Card.Body>
            <Card.Title>8. Privacy Policy</Card.Title>
            <Card.Text>
              Your use of the Website is also governed by our Privacy Policy, which explains how we collect, use, and protect your personal data. By using Taxclip.co.uk, you agree to the collection and use of your information as described in our Privacy Policy.
            </Card.Text>
          </Card.Body>
        </Card>

        {/* Section 9: Changes to the Terms of Service */}
        <Card className="mb-3">
          <Card.Body>
            <Card.Title>9. Changes to the Terms of Service</Card.Title>
            <Card.Text>
              We may update these Terms from time to time. If we make significant changes, we will notify you via email or by posting a notice on our Website. Your continued use of the Website after such changes indicates your acceptance of the revised Terms.
            </Card.Text>
          </Card.Body>
        </Card>

        {/* Section 10: Termination */}
        <Card className="mb-3">
          <Card.Body>
            <Card.Title>10. Termination</Card.Title>
            <Card.Text>
              We reserve the right to terminate or suspend your access to Taxclip.co.uk, without notice or liability, for any reason, including if you violate these Terms. Upon termination, your right to use the Website will cease immediately.
            </Card.Text>
          </Card.Body>
        </Card>

        {/* Section 11: Governing Law */}
        <Card className="mb-3">
          <Card.Body>
            <Card.Title>11. Governing Law</Card.Title>
            <Card.Text>
              These Terms shall be governed by and construed in accordance with the laws of England and Wales. Any disputes arising out of these Terms or your use of the Website shall be subject to the exclusive jurisdiction of the courts of England and Wales.
            </Card.Text>
          </Card.Body>
        </Card>

        {/* Section 12: Contact Information */}
        <Card className="mb-3">
          <Card.Body>
            <Card.Title>12. Contact Information</Card.Title>
            <Card.Text>
              If you have any questions about these Terms or the Website, please contact us at:
              <br />
              <strong>support@taxclip.co.uk</strong><br />
              <br />
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  </Container>
);

export default TermsOfServicePage;
