// src/components/HomePage.js
import React from 'react';
import { Container, Row, Col, Jumbotron, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const HomePage = () => (
    <Container className="mt-5 text-center">
      <h1>Welcome to TaxClip.co.uk</h1>
      <p>Calculate your taxes easily with our free to use tools.</p>
      <Button variant="primary" as={Link} to="/calculator">
        Go to Income Tax Calculator
      </Button>
    </Container>
  );

export default HomePage;
