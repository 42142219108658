// src/components/AboutPage.js
import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const AboutPage = () => (
  <Container className="mt-5">
    <Row>
      <Col>
        <h1>About Us</h1>
        <p>
          Welcome to TaxClip. We want to make tax calculations simple and accessible for everyone.
        </p>
      </Col>
    </Row>
    <Row className="mt-4">
      <Col md={4}>
        <Card>
          <Card.Body>
            <Card.Title>Our Mission</Card.Title>
            <Card.Text>
              To provide an easy-to-use platform for tax calculations and finding an expert.
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col md={4}>
        <Card>
          <Card.Body>
            <Card.Title>Our Terms of Service</Card.Title>
            <Card.Text>
              Please find our terms of service and privacy policy  <Link to="/terms-of-service">here</Link>.
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col md={4}>
        <Card>
          <Card.Body>
            <Card.Title>Contact Us</Card.Title>
            <Card.Text>
              Please feel free to reach out at <a href="mailto:support@taxclip.co.uk">support@taxclip.co.uk</a>.
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  </Container>
);

export default AboutPage;
